import { computed, onMounted, onUnmounted, reactive } from 'vue'
import { injectEngine } from './use-engine'
import Browser from '@utils/browser-detect'
import Slice from '@controllers/experiences/vinyl/slice'

let CACHE = null

export function useVinyl () {
  const engine = injectEngine()

  const slices = !CACHE 
    ? generateSlices({
      radius: 15, 
      minSize: 12, 
      maxSize: 15, 
      minDegrees: 360 / 8, 
      maxDegrees: 360 / 8,
    })
    : CACHE

  if (!CACHE) {
    CACHE = slices
  }

  function onUpdate (elapsed) {
    slices.forEach(slice => slice.update(elapsed * 0.00025, {
      x: 1, // this.context.mouse.x.value, 
      y: 1, // this.context.mouse.y.value,
    }))
  }

  engine.onUpdate(onUpdate)

  function generateSlices ({ radius = 20, minSize = 12, maxSize = 15, minDegrees = 15, maxDegrees = 25 }) {
    const slices = []

    const minSliceDegrees = minDegrees
    const maxSliceDegrees = maxDegrees
    
    let degreesleft = 360
    let startAt = 0

    while (degreesleft > 0) {
      let endAt = startAt

      if (degreesleft > maxSliceDegrees) {
        endAt += random(minSliceDegrees, maxSliceDegrees)
      } else {
        endAt += degreesleft
      }

      const slice = new Slice({
        start: startAt,
        end: endAt,
        radius: radius,
        minSize: minSize,
        maxSize: maxSize,
        resolution: Browser.isMobile ? 4 : 15, // random(4, 7),
        wireframeResolution: 0, // Browser.isMobile ? 4 : 16, // 8,
        // color: '#ffffff',

        // wireframeResolution: 4,
        color: Browser.isMobile ? '#363636' : '#161616',
        // color: '#181818',
        alpha: 1,
        instances: 1,
      })


      slices.push(slice)

      degreesleft -= endAt - startAt
      startAt = endAt
    }

    return slices
  }

  function random (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  
  onMounted(() => {
    engine.addMeshesToScene(slices.map(slice => slice.mesh))
  })

  onUnmounted(() => {
    slices.forEach(slice => {
      engine.offUpdate(onUpdate)
      engine.removeMeshFromScene(slice.mesh)
    })
  })

  return {

  }
}

export default useVinyl