<template>
  <div class="w-full h-max w-max-screen pb-10">
    
    <div class="relative font-semibold px-5 pt-10 xs:px-5 lg:px-0 xs:pt-10 h-full w-full max-w-6xl mx-auto tracking-wider flex items-stretch max-h-24/24">
      <div class="h-full xs:w-full lg:w-18/24">
        <h1 class="section-title font-serif font-bold text-primary-light text-2xl md:text-4xl leading-10 mb-5 xxl:mb-10 self-stretch ">
          À propos — 
        </h1>

        <h2 class="leading:7 md:leading-9 text-sm xs:text-base">
          Je m'appelle Guillaume Rouxel.
          <br>
          Je suis développeur web spécialisé en Javascript.
          <br>
          Je réalise des projets en tant qu'indépendant depuis 2017.
          <br>
          Breton d'origine, parisien par adoption.
        </h2>

        <h3 class="leading:7 md:leading-9 text-xs lg:text-sm mt-5">
          Vue.js, Node.js, Feathers.js, MongoDB
        </h3>

        <h1 class="section-title font-serif font-bold text-primary-light text-2xl md:text-4xl leading-10 mt-10 mb-5 xxl:my-10">
          Projets —
        </h1>
        
        <h2 
          v-for="project in projects"
          :key="project._id"
          class="leading:7 md:leading-9 uppercase mb-5 md:mb-2"
        >
          <span 
            class="inline-block mr-3"
            :style="{ color: project.color }"
          >
            ᑀ
          </span> 

          <a
            v-if="project.url"
            :href="project.url"
            target="_blank"
          >
            {{ project.title }}
          </a>

          <span
            v-if="!project.url"
          >
            {{ project.title }}
          </span>

          <br class="block xs:hidden">

          <span class="text-primary-dark">
            — <small>{{ project.client }} </small>
          </span> 
        </h2>

        <h1 class="section-title font-serif font-bold text-primary-light text-2xl md:text-4xl leading-10 mt-10 mb-5 xxl:my-10">
          Contact | Devis — 
        </h1>

        <h2 class="leading-9">
          hi <small class="text-primary-dark">at</small> grxl <small class="text-primary-dark">.</small> fr
        </h2>

        <h2 class="leading-9">
          <a 
            href="https://www.malt.fr/profile/guillaumerouxel1"
            target="_blank"
          >
            Malt
          </a>
        </h2>
      </div>

      <div class="relative hidden xs:block xs:w-6/24 self-stretch pr-16 xs:pb-20 lg:pb-10">
        <div 
          v-for="year in years"
          :key="year"
          :class="[
            `relative h-${Math.ceil(24 / years.length)}/24 flex justify-between`,
          ]"
        >
          <h1 class="absolute left-full top-0 ml-3 font-serif font-bold text-primary-dark text-2xl text-right">
            {{ year }}
          </h1>

          <div 
            v-for="project in projectsByYear"
            :key="`timeline-${project._id}`"
            class="h-full w-1"
            :style="{ 
              backgroundColor: project.color,
              opacity: isCurrentYear(year, project) ? 1 : 0.05,
            }"
          ></div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  // import HomeExperience from '@components/home-experience'
  import { computed } from '@vue/runtime-core'
  import { useVinyl } from '@composables/webgl/use-vinyl'
  import { projects } from '@assets/data/projects'
  import dayjs from 'dayjs'
  import customParseFormat from 'dayjs/plugin/customParseFormat'
  import isBetween from 'dayjs/plugin/isBetween'
  
  dayjs.extend(isBetween)
  dayjs.extend(customParseFormat)

  export default {
    setup () {
      useVinyl()

      const years = Array(new Date().getFullYear() - 2016)
        .fill(0)
        .map((_, year) => 2017 + year)

      function isCurrentYear (year, project) {      
        const date = dayjs(project.begin, 'DD-MM-YYYY').year(year)

        return project.end === null || date.isBetween(
          dayjs(project.begin, 'DD-MM-YYYY'), 
          dayjs(project.end, 'DD-MM-YYYY'),
          null,
          '[]'
        )
      }

      const projectsByYear = computed(() => {
        console.log(`projects`, projects)
        return projects.sort((a, b) => {
          return dayjs(a.begin, 'DD-MM-YYYY').year() > dayjs(b.begin, 'DD-MM-YYYY').year()
            ? 1
            : -1
        }) 
      })

      return {
        projects,
        projectsByYear,
        years,
        isCurrentYear,
      }
    },
  }
</script>

<style lang="scss">
  .section-title {
    position: relative;
    width: max-content;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 20px);
      height: 0.85rem;
      transform: translate3d(-50%, 0, 0);
      z-index: -1;
      background: #4a4a4a; // rgba(255, 255, 255, 0.2);
    }
  }
</style>