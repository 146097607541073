import {
  InstancedBufferGeometry,
  InstancedBufferAttribute,
  BufferAttribute,
  Color,
  ShaderMaterial,
  DoubleSide,
  Mesh,
  EllipseCurve,
  ImageUtils,
} from 'three'
import lerp from '@utils/lerp'
import SliceVertex from '../shaders/slice.vert'
import SliceFragment from '../shaders/slice.frag'
import textureUrl from '@assets/images/textures/grunge-noise.jpg'
export default class Slice {
  constructor (options = {}) {
    const radius = options.radius || this.random(7, 11)

    this.options = Object.assign({
      radius: radius,
      height: radius + this.random(options.minSize || 15, options.maxSize || 20),
      resolution: this.random(3, 50, true),
      color: '#ffffff',
      alpha: 1,
      instances: 1,
      wireframeResolution: 25,
    }, options)
    
    const instances = this.options.instances

    /*
    *   ANIMATION
    */
    this.inverted = false

    this.color = new Color(this.options.color)
    this.alpha = this.options.alpha
    
    this.resolution = this.options.resolution
    this.radius = this.options.radius
    this.height = this.options.height 
    
    this.start = this.options.start * Math.PI / 180
    this.end = this.options.end * Math.PI / 180

    this.speed = 0.001

    const { 
      vertices, 
      angles, 
      easings, 
      distance, 
      uvs, 
      offsets 
    } = this.getPoints()

    this.geometry = new InstancedBufferGeometry()
    this.geometry.setAttribute('position', new BufferAttribute(vertices, 3))
    this.geometry.setAttribute('angle', new BufferAttribute(angles, 3))
    this.geometry.setAttribute('easing', new BufferAttribute(easings, 3))
    this.geometry.setAttribute('uv', new BufferAttribute(uvs, 3))
    this.geometry.setAttribute('offset', new InstancedBufferAttribute(offsets, 1, true, 1))

    this.geometry.maxInstancedCount = this.options.instances

    this.material = new ShaderMaterial({
      uniforms: {
        color: { type: 'c', value: this.color },
        alpha: { type: 'f', value: this.alpha },
        speed: { type: 'f', value: 0 },
        time: { type: 'f', value: 0 },
        mouse: { type: 'v2', value: { x: 1, y: 1 } },
      },
      vertexShader: SliceVertex
        .replace(/{{\s*HEIGHT\s*}}/g, `${window.innerHeight}.0`)
        .replace(/{{\s*WIDTH\s*}}/g, `${window.innerWidth}.0`),
      fragmentShader: SliceFragment,
      transparent: true,
      depthTest: true,
      blending: 2,
      side: DoubleSide,
      // wireframe: false,
      wireframe: this.resolution > this.options.wireframeResolution ? false : true,
      wireframeLinewidth: 0.1,
    })

    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.frustumCulled = false
  }

  update (time, mouse) {
    const distanceFromCenter = Math.abs(
      this.distance(mouse.x, mouse.y,  window.innerWidth / 2, window.innerHeight / 2)
    )

    let progression = 1 - (distanceFromCenter / (window.innerWidth / 4))

    this.mesh.material.uniforms.time.value = time
    this.mesh.material.uniforms.speed.value += this.speed // lerp(0.1, 0.5, progression)  // this.speed 
    this.mesh.material.uniforms.alpha.value = this.alpha
    this.mesh.material.uniforms.mouse.value = mouse || { x: 1, y: 1 }

    // this.mesh.rotation.z += lerp(0, 0.5, progression)
  }

  getPoints () {
    const distance = { 
      bottom: this.radius, 
      top: this.height
    }

    let bottom = new EllipseCurve(0,  0, distance.bottom, distance.bottom, this.start, this.end, false, 0)
    let top = new EllipseCurve(0,  0, distance.top, distance.top, this.start, this.end , false, 0)
    
    let points = {
      top: top.getPoints(this.resolution).reverse(),
      bottom: bottom.getPoints(this.resolution).reverse()
    }

    let easing = this.random(-3, 3)

    while (easing === 0) {
      easing = this.random(-3, 3)
    }

    easing *= Math.random()

    const vertices = new Float32Array(this.resolution * 6 * 3)
    const angles = new Float32Array(this.resolution * 6 * 3)
    const easings = new Float32Array(this.resolution * 6 * 3)
    const uvs = new Float32Array(this.resolution * 6 * 3)
    const offsets = new Float32Array(this.options.instances * 2)

    let index = 0

    for (let i = 0; i < vertices.length; i += 18) {
      const triangle = [
        points.top[index].x, points.top[index].y, 0,
        points.bottom[index].x, points.bottom[index].y, 0,
        points.top[index + 1].x, points.top[index + 1].y, 0,
        
        points.bottom[index].x, points.bottom[index].y, 0,
        points.bottom[index + 1].x, points.bottom[index + 1].y, 0,
        points.top[index + 1].x, points.top[index + 1].y, 0,
      ]

      const x1 = points.top[index].x - points.bottom[index].x
      const y1 = points.top[index].y - points.bottom[index].y

      const x2 = points.top[index + 1].x - points.bottom[index + 1].x
      const y2 = points.top[index + 1].y - points.bottom[index + 1].y
      
      const angle1 = Math.atan2(y1, x1)
      const angle2 = Math.atan2(y2, x2)

      const angle = [
        angle1, angle1, angle1,
        angle1, angle1, angle1,
        angle2, angle2, angle2, 

        angle1, angle1, angle1,
        angle2, angle2, angle2, 
        angle2, angle2, angle2, 
      ]

      for (let j = 0; j < triangle.length; j++) {
        vertices[i + j] = triangle[j]
        angles[i + j] = angle[j]
        easings[i + j] = easing
        uvs[i + j] = i + j / (this.resolution * 6)
      }

      index += 1
    }

    return { vertices, angles, easings, distance, uvs, offsets }
  }

  random (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  radians (degrees) {
    return degrees * Math.PI / 180
  }
  
  distance (x1, y1, x2, y2) {
    return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2))
  }
  
  map (value, start1, stop1, start2, stop2) {
    return (value - start1) / (stop1 - start1) * (stop2 - start2) + start2
  }
}
